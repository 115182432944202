import { VideoDescriptor } from '@libs/waymark-video/video-descriptor';
import { LegacyVideoConfiguration } from '@libs/waymark-video/video-descriptor-types';

import { AnyVideoDescriptor } from '@libs/waymark-video/video-descriptor/src/migrations/types';

export const isVideoDescriptor = (obj: unknown): obj is AnyVideoDescriptor =>
  typeof obj === 'object' && obj !== null && 'projectManifest' in obj && 'templateManifest' in obj;

export const getVideoDescriptorFromVideoDescriptorDataOrActiveConfiguration = (
  videoData: AnyVideoDescriptor | LegacyVideoConfiguration,
  templateSlug: string,
): Promise<VideoDescriptor> => {
  if (isVideoDescriptor(videoData)) {
    return VideoDescriptor.from(videoData);
  } else {
    return VideoDescriptor.from({ templateSlug, configuration: videoData });
  }
};
