/**
 * Additional data to be included in log messages.
 *
 * You can pass anything that can be serialized to JSON, but having a type makes the intended use
 * clear.
 */
export type LogRecordData = Record<string, any>;

/**
 * Error with extra data that should be logged.
 */
export class LogRecordDataError extends Error {
  public readonly cause?: Error;
  public readonly data?: LogRecordData;
  constructor(message: string, options: { cause?: Error; data?: LogRecordData } = {}) {
    const { cause, data } = options;
    super(message);
    this.name = this.constructor.name;
    this.cause = cause;
    this.data = data;
  }
}
