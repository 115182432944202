import { graphql } from '@libs/graphql-types';
import {
  ACCOUNT_FETCH_COMPLETE,
  ACCOUNT_RESET_EMAIL_COMPLETE,
  ACCOUNT_LOGOUT,
} from 'app/state/actionTypes.js';
import * as selectors from 'app/state/selectors/index.js';
import { apolloClient } from 'shared/api/graphql';
import Intercom from 'shared/utils/intercom.js';

const accountIntercomInfoQuery = graphql(/* GraphQL */ `
  query AccountIntercomInfo($accountGUID: String!) {
    accountByGuid(guid: $accountGUID) {
      id
      guid
      displayName
      emailAddress
      partner {
        id
        slug
        name
      }
      subscriptions {
        edges {
          node {
            id
            status
            subscriptionPlan {
              id
              slug
            }
          }
        }
      }
    }
  }
`);

/**
 * Updates the contact info for the user registered with intercom.
 * This should be called when we load a user's account for the first time or
 * if part of their contact info is updated, ie resetting email.
 *
 * @param {object}  newContactInfo
 * @param {string}  newContactInfo.user_id  The user's account GUID, ie the id tied to this user's intercom contact record
 *                                            The user_id property must always be provided when updating the user's contact info
 * @param {string}  [newContactInfo.email]  The user's email address
 * @param {string}  [newContactInfo.name]   The user's name tied to the account
 */
const updateIntercomContact = (newContactInfo) => {
  Intercom('update', newContactInfo);
};

/**
 * Middleware for managing chat-related side effects from redux actions
 */
const trackingInfoMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case ACCOUNT_FETCH_COMPLETE: {
        // When an account is successfully fetched, update the user's contact
        // with useful info about the account
        const accountGUID = action.payload.guid;

        apolloClient
          .query({
            query: accountIntercomInfoQuery,
            variables: { accountGUID },
          })
          .then(({ data }) => {
            const accountData = data.accountByGuid;

            if (!accountData) {
              return;
            }

            // Get the subscription plan slug for the user's active subscription, if applicable
            const activeSubscriptionPlanSlug =
              accountData.subscriptions?.edges.find(
                (edge) => edge?.node?.status.toLowerCase() === 'active',
              )?.node?.subscriptionPlan?.slug ?? null;

            updateIntercomContact({
              user_id: accountGUID,
              email: accountData.emailAddress,
              name: accountData.displayName ?? null,
              partner_name: accountData.partner?.name ?? null,
              partner_slug: accountData.partner?.slug ?? null,
              active_subscription_plan_slug: activeSubscriptionPlanSlug,
            });
          });

        break;
      }

      case ACCOUNT_RESET_EMAIL_COMPLETE: {
        // When the user resets their email, make sure we update the email associated with
        // their intercom contact
        const accountGUID = selectors.getAccountGUID(getState());

        const newAccountEmail = action.payload;

        updateIntercomContact({ user_id: accountGUID, email: newAccountEmail });

        break;
      }

      case ACCOUNT_LOGOUT:
        // Clear intercom's session cookies now that the user is logged out
        Intercom('shutdown');

        break;

      default:
    }

    return next(action);
  };

export default trackingInfoMiddleware;
