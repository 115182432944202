import { LocationDescriptorObject } from 'history';

// Local
import history from 'app/state/history';
import { persistentQueryParams } from 'app/constants/urls';

/**
 * Navigates to a url within the front-end app
 *
 * @param {string}  url             URL path to navigate to
 * @param {bool}    shouldReplace   Whether the new path should replace the previous path in the location history
 * @param {object}  queryParms      Object representing all query params to apply to the url
 */
export const goToInternalURL = (
  url: string | URL | LocationDescriptorObject,
  shouldReplace = false,
  shouldPreservePersistentQueryParams = true,
) => {
  let parsedURL: URL;

  if (url instanceof URL) {
    parsedURL = url;
  } else if (typeof url === 'string') {
    parsedURL = new URL(url, window.location.origin);
  } else {
    parsedURL = new URL(`${url.pathname ?? window.location.pathname}`, window.location.origin);
    if (url.search) {
      parsedURL.search = url.search;
    }
    if (url.hash) {
      parsedURL.hash = url.hash;
    }
  }

  if (shouldPreservePersistentQueryParams) {
    const currentQueryParams = new URLSearchParams(window.location.search);

    for (const persistentQueryParamKey of persistentQueryParams) {
      if (parsedURL.searchParams.has(persistentQueryParamKey)) {
        continue;
      }

      const currentParamValue = currentQueryParams.get(persistentQueryParamKey);

      if (currentParamValue !== null) {
        parsedURL.searchParams.set(persistentQueryParamKey, currentParamValue);
      }
    }
  }

  const locationDescriptor = {
    pathname: parsedURL.pathname,
    search: parsedURL.search,
    hash: parsedURL.hash,
  };

  if (shouldReplace) {
    history.replace(locationDescriptor);
  } else {
    history.push(locationDescriptor);
  }
};
