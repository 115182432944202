import { graphql } from '@libs/graphql-types';
import { useQuery } from '@apollo/client';

import { apolloClient } from '../index';

const STOCK_IMAGES_FOR_IMAGE_KEYS_QUERY = graphql(/* GraphQL */ `
  query StockImagesForImageKeys($imageKeys: [String!]!) {
    stockImagesForImageKeys(imageKeys: $imageKeys) {
      id
      stockAssetId
      stockSearchId
    }
  }
`);

/**
 * Query fetches all stock images which match an array of image keys (found via ImageAsset.getKey())
 */
export const getStockImagesForImageKeys = async (imageKeys: string[]) => {
  if (!imageKeys || imageKeys.length === 0) {
    return [];
  }

  const { data } = await apolloClient.query({
    query: STOCK_IMAGES_FOR_IMAGE_KEYS_QUERY,
    variables: { imageKeys },
  });

  return data.stockImagesForImageKeys;
};

export const IMAGE_LIBRARY_IMAGE_DATA_FOR_IMAGE_ASSET_KEY_QUERY = graphql(/* GraphQL */ `
  query ImageLibraryImageByImageKey($imageKey: String!) {
    imageLibraryImageByImageKey(imageKey: $imageKey) {
      id
      guid
      baseUrl
      upscaledImageUrl
      imageType
      imageWidth
      imageHeight
    }
  }
`);

/**
 * Query fetches a single stock/uploaded image by its key (found via ImageAsset.getKey())
 */
export const useImageLibraryImageDataForImageAssetKey = (imageKey?: string) => {
  const { data, loading } = useQuery(IMAGE_LIBRARY_IMAGE_DATA_FOR_IMAGE_ASSET_KEY_QUERY, {
    variables: {
      imageKey: imageKey ?? '',
    },
    skip: !imageKey,
  });

  return {
    imageLibraryImage: data?.imageLibraryImageByImageKey ?? null,
    isLoading: loading,
  };
};
