// Vendor
import type {
  APIGatewayProxyEventV2,
  APIGatewayProxyStructuredResultV2,
  Context as LambdaContext,
} from 'aws-lambda';

// Local
import { App } from './app';
import { Router } from './router';
import { CORSMiddleware } from './middleware';

// Re-exports for consumers of this library
export * from './app';
export * from './context';
export * from './httpLib';
export * from './middleware';
export * from './request';
export * from './router';

export interface AppHandlerOptions {
  shouldUseCors?: boolean;
}

const defaultOptions: AppHandlerOptions = {
  shouldUseCors: true,
};

// A standard app handler for our most common use case.
export const createDefaultAppHandler = (
  router: Router,
  options: AppHandlerOptions = defaultOptions,
) => {
  const app = new App();

  if (options.shouldUseCors) {
    app.use(CORSMiddleware);
  }

  // Set up the router endpoints.
  app.use(router.middleware());

  const handler = async (
    event: APIGatewayProxyEventV2,
    lambdaContext?: LambdaContext,
  ): Promise<APIGatewayProxyStructuredResultV2> => {
    return app.handle(event, lambdaContext);
  };

  return handler;
};
